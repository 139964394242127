

/* Container */
.medici-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* Ensure no horizontal overflow */
    padding: 2rem 1rem;
    /* Add padding for better spacing */
}

/* Header */
.header-medici {
    position: relative;
    top: 7rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Subtitle */
.subtitle-medici {
    color: #000000;
    font-family: "Candlefish-Regular";
    font-size: 1.875rem;
    font-weight: 400;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
    margin: 2% 0;
}


.nu-gasit{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
}

.btn-medici-inscrie{
    width: 15vw;
    height: 5vh;
    background-color: black;
    color: #d4b43b;
    font-size: 2rem;
    border-radius: 20px;
    cursor: pointer;
    animation: pulsate 1.5s infinite;
}


@keyframes pulsate {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Title */
.title-medici {
    color: #000000;
    font-family: "Candlefish-Regular";
    font-size: 6.875rem;
    font-weight: 400;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}

/* Input Search */
.input-search-medici {
    border-radius: 20px;
    height: 3rem;
    width: 100%;
    max-width: 300px;
    font-size: 1.25rem;
    padding: 0.5rem;
}

/* Down Filter Section */
.down-filter {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 1rem;
}

/* Filtering Section */
.filtering-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    /* Ensure it doesn't exceed viewport width */
}

/* Individual Filters */
.oras,
.specializare,
.order {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    color: white;
}

select {
    background-color: #ffffff;
    font-size: 16px;
    border-radius: 20px;
    font-weight: 500;
    letter-spacing: 3px;
    font-family: sans-serif;
    color: black;
    padding: 2%;
    width: 20rem;
}



/* Medici List */
.medici-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 90%;
    padding: 1rem;
    margin: 5% 0;
}

/* Media Queries */
@media (max-width: 768px) {
    .subtitle-medici {
        font-size: 1.5rem;
    }

    .title-medici {
        font-size: 5rem;
    }

    .input-search-medici {
        width: 40vw;
    }

    .pagination{
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        max-width: 80%;
    }

        /* Down Filter Section */
        .down-filter {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            justify-content: center;
            width: 70%;
            padding: 1rem;
        }

    .medici-list {
        width: 70%;
        gap: 5rem;
        /* Ensure full width on smaller screens */
        margin: 10% 0;
        padding: 0;
    }

    .button-medic{
        width: 6rem;
    }
}

@media (max-width: 480px) {
    .subtitle-medici {
        font-size: 1.2rem;
        max-width: 80%;
    }

    .title-medici {
        font-size: 4rem;
    }

    .input-search-medici {
        width: 40vw;
    }

    .down-filter {
        gap: 3rem;
    }

    .medici-list {
        width: 100%;
        padding: 0;
        margin: 20% 0;
    }

    .medici-card1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}



.medic-image {
    width: 100%;
    /* Set to 100% for responsive width */
    max-width: 300px;
    /* Max width for larger screens */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: contain;
    border-radius: 20px;
}

.medic-info {
    display: flex;
    flex-direction: column;
    gap: 1%;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* Set to 100% for responsive width */
}

.medic-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
}

.medic-specialization,
.medic-city,
.medic-phone {
    font-size: 18px;
    margin: 4px 0;
}

.medic-button:hover {
    background-color: #d4b43b;
}

.details-button,
.appointment-button {
    background-color: #E8C547;
    color: rgb(0, 0, 0);
    width: 100%;
    /* Max width for larger screens */
    border-radius: 20px;
    font-size: 16px;
    height: 3rem;
    margin: 8px 0;
    /* Add margin for spacing */
}

.pagination{
    display: flex;
    flex-wrap: wrap;
    max-width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.button-container {
    display: flex;
    justify-content: center;
    margin: 10px 5px;
    /* Adjust margins as needed */
}

.button-3d {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    border-width: 0;
    padding: 0;
    min-width: 4em;
    min-height: 4em;
    box-sizing: border-box;
    background: transparent;
    font: inherit;
    cursor: pointer;
    margin: 10px;
    border-radius: 20px;
}

.button-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    padding: 30%;
    background-color: black;
    transform: translateY(0);
    color: #fff;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transition: transform 0.3s, border-radius 0.3s, background 10s;
}

.button-3d:active .button-top {
    border-radius: 10px 10px 8px 8px / 8px;
    transform: translateY(2px);
}

.button-bottom {
    position: absolute;
    z-index: 1;
    bottom: 4px;
    left: 4px;
    border-radius: 20px;
    padding-top: 6px;
    width: calc(100% - 8px);
    height: calc(100% - 10px);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
    transition: border-radius 0.2s, padding-top 0.2s;
}

.button-base {
    position: absolute;
    z-index: 0;
    top: 4px;
    left: 0;
    border-radius: 20px;
    width: 100%;
    height: calc(100% - 4px);
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 1px 0 rgba(255, 255, 255, 0.75),
        inset 0 2px 2px rgba(0, 0, 0, 0.25);
    transition: border-radius 0.2s, padding-top 0.2s;
}

.button-3d:active .button-bottom {
    border-radius: 10px 10px 8px 8px / 8px;
    padding-top: 0;
}

.button-3d:active .button-base {
    border-radius: 10px 10px 8px 8px / 8px;
}

.button-3d.active .button-top {
    background-color: #ffffff;
    background-image: none;
    color: black;
    font-weight: bold;
    /* Remove gradient */
}

.button-3d.active .button-bottom,
.button-3d.active .button-base {
    background-image: none;
    /* Remove gradient */
}

@media screen and (max-width: 768px) {
 
    .medic-name {
        font-size: 22px;
    }

    .medic-specialization,
    .medic-city,
    .medic-phone {
        font-size: 16px;
    }

    .details-button,
    .appointment-button {
        font-size: 14px;
        max-width: 8rem;
        /* Adjust max width for tablets */
        height: 2.5rem;
    }
}

@media screen and (max-width: 480px) {
    
    .medic-name {
        font-size: 20px;
    }

    .medic-specialization,
    .medic-city,
    .medic-phone {
        font-size: 14px;
    }

    .details-button,
    .appointment-button {
        font-size: 12px;
        max-width: 100%;
        /* Full width for mobile */
        height: 2.5rem;
    }
}