body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.navbar {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #0000007c;
    color: #fff;
    font-family: Candlefish;
    font-size: 18px;
    backdrop-filter: blur(100px);
    font-style: normal;
    width: 85%;
    height: 7.5%;
    border-radius: 10px;
    z-index: 1000;
}

.logo-container {
    margin-top: 0.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20rem;
    height: 80%;
    cursor: pointer;
}

.logo-image {
    max-width: 100%;
    height: auto;
    margin: 0;
}

.nav-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    text-decoration: none;
}

.nav-item {
    background: none;
    border: none;
    text-decoration: none;
    color: white;
    cursor: pointer;
    padding: 10px 15px;
    font-size: 1em;
}



.btn {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #E8C547;
    min-width: 18rem;
    border: 0;
    height: 3.5rem;
    border-radius: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 16px 20px;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
}

.cta{
    color: black;
    letter-spacing: 5px;
}
.btn:hover {
    opacity: .95;
}

.btn .animation {
    border-radius: 100%;
    animation: ripple 1s linear infinite;
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 0 0 20px rgba(0, 0, 0, 0.1), 0 0 0 40px rgba(0, 0, 0, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(3, 1, 1, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
    }
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
    justify-content: end;
    align-items: end;
}

.line1 {
    width: 25px;
    height: 3px;
    background-color: #E8C547;
}

.line2 {
    width: 20px;
    height: 3px;
    background-color: #E8C547;
}



@media (max-width: 950px) {
    .navbar {
        justify-content: center;
    }

    .nav-links {
        display: none;
        flex-direction: column;
        gap: 0;
        font-size: 18px;
        position: absolute;
        top: 80px;
        border-radius: 20px;
        right: 0;
        background-color: #333333;
        width: 100%;
        padding: 5%;
    }

    .logo-container {
        width: 20rem;
        justify-content: center;
    }

    .logo-image {
        max-width: 100%;
        height: auto;
    }

    .centered-buttons {
        display: flex;
        flex-direction: column;
    }

    .nav-links.open {
        display: flex;
    }

    .nav-item {
        width: 100%;
        text-align: center;
        padding: 15px 0;
        border-top: 1px solid #444;
    }

    .hamburger {
        display: flex;
    }

    .line1 {
        width: 50px;
        background-color: #E8C547;
    }

    .line2 {
        width: 30px;
        background-color: #E8C547;
    }
}