/* Modal styles */
.modal {
    display: flex;
    position: fixed;
    z-index: 200000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    /* Adds blur to the background */
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: linear-gradient(#212121, #212121) padding-box,
        linear-gradient(125deg, transparent 45%, #e8c54780) border-box;
    padding: 3%;
    border: 2px solid transparent;
    width: 90%;
    max-width: 55vw;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    border-radius: 1em;
    outline: none;
    text-align: center;
    /* Center align text */
}

.logo-container-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.logo-modal {
    max-width: 60%;
    height: auto;
}

.close {
    color: #f83939;
    font-size: 3.5rem;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
    transition: transform 0.3s ease;
    /* Smooth scale transition */
}

.close:hover,
.close:focus {
    color: #ff0000;
    transform: scale(1.5);
    text-decoration: none;
    cursor: pointer;
}

.form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-group {
    display: flex;
    flex-direction: row;
    gap: 0%;
    margin-bottom: 1%;
    align-items: center;
    justify-content: space-between;
}

.input-container {
    position: relative;
    flex: 1;
    margin-right: 10px;
}

.input-container:last-child {
    margin-right: 0;
}

.input-container input {
    width: 70%;
    padding: 2%;
    background: #21212100;
    border-bottom: 2px solid #ffffff9a;
    transition: 0.1s;
    font-weight: 300;
    font-size: 1rem;
    color: white;
}

.input-container input:focus {
    border-bottom: 3px solid #e8c547;
    /* Darker border on focus */
    outline: none;
}

input::placeholder {
    color: #ffffff;
}

.form-message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
}

.form-message.success {
    background-color: #4caf50;
}

.form-message.error {
    background-color: #f44336;
}

.button-modal {
    width: 30vw;
    position: relative;
    height: 2.5em;
    margin-top: 2%;
    border: 3px ridge #e8c547;
    outline: none;
    background-color: #212121;
    color: white;
    transition: 1s;
    border-radius: 0.3em;
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: bold;
    cursor: pointer;
}

.button-modal::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 3%;
    width: 95%;
    height: 40%;
    background-color: #212121;
    transition: 0.5s;
    transform-origin: center;
}

.button-modal::before {
    content: "";
    transform-origin: center;
    position: absolute;
    top: 80%;
    left: 3%;
    width: 95%;
    height: 40%;
    background-color: #212121;
    transition: 0.5s;
}

.button-modal:hover::before,
.button-modal:hover::after {
    transform: scale(0);
}

.button-modal:hover {
    font-weight: bold;
    transform: scale(1.1);
}

/* Media query for larger screens */
@media (max-width: 950px) {
    .modal {
        z-index: 200000;
    }

    .form-container {
        max-height: 60dvh;
    }

    .modal-content {
        height: 90%;
    }

    .logo-container-modal {
        width: 50%;
    }

    .label-input-container{
        margin: 0;
    }
    .logo-modal{
        width: 100%;
        position: relative;
    }

    .form-group {
        flex-direction: column;
    }

    .input-container {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
    }

    .input-container span {
        left: 10px;
        top: 10px;
        font-size: 16px;
    }

    .input-container input:focus+span,
    .input-container input:not(:placeholder-shown)+span {
        top: -20px;
        left: 10px;
        font-size: 12px;
        color: #fff;
    }
}