/* Global Box-Sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Existing CSS */
.home-container {
    min-height: 100vh;
    width: 100vw;
    background-image: url("../assets/home3.webp");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    font-family: "Candlefish-Regular";
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    /* Ensure no horizontal overflow */
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    max-width: 100%;
    position: relative;
    top: 2rem;
    padding: 0 1rem;
}

.header-title {
    font-size: 120px;
    line-height: 80%;
    text-align: center;
    color: white;
    margin: 2rem;
}

.header-subtitle {
    color: #E8C547;
    position: relative;
    font-size: 30px;
    text-shadow: 0px 0px 5px black;
    font-weight: 300;
    margin: 2rem;
    width: 100%;
    text-align: center;
}

.search-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    /* Ensure it does not exceed the viewport width */
    padding: 0 1rem;
    /* Add padding for better spacing on smaller screens */
}

.cautaMedic {
border-radius: 4px;
    background-color: #E8C547;
    border: none;
    color: #292929;
    text-align: center;
    font-size: 28px;
    padding: 16px;
    letter-spacing: 3px;
    width: 20rem;
    transition: all 0.5s;
    border-radius: 20px;
    cursor: pointer;
    margin: 3% 0;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, .7);
    cursor: pointer;
        display: inline-block;
        position: relative;
        transition: 0.5s;
        -webkit-animation: pulse 3s infinite cubic-bezier(0.66, 0, 0, 1);
            -moz-animation: pulse 3s infinite cubic-bezier(0.66, 0, 0, 1);
            -ms-animation: pulse 3s infinite cubic-bezier(0.66, 0, 0, 1);
            animation: pulse 3s infinite cubic-bezier(0.66, 0, 0, 1);
}

@-webkit-keyframes pulse {
    to {
        box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
}

@-moz-keyframes pulse {
    to {
        box-shadow: 0 0 0 45px rgba(128, 128, 128, 0);
    }
}

@-ms-keyframes pulse {
    to {
        box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
}

@keyframes pulse {
    to {
        box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
}

.cautaMedic:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 14px;
    right: -20px;
    transition: 0.5s;
}

.cautaMedic:hover {
    padding-right: 24px;
    padding-left: 8px;
}

.cautaMedic:hover:after {
    opacity: 1;
    right: 10px;
}

@media screen and (max-width: 980px) {
    .home-container {
        height: 100vh;
        width: 100%;
    }

    .header {
        width: 80%;
    }

    .header-title {
        font-size: 80px;
        text-align: center;
        color: white;
    }

    .header-subtitle {
        color: #E8C547;
        font-size: 22px;
        font-weight: 300;
        text-align: center;
    }

    .search-holder {
        flex-direction: column;
    }

    .input-search {
        width: 100%;
        max-width: 300px;
    }

    .submit {
        width: 100%;
        max-width: 200px;
    }
}

@media (max-width: 768px) {
    .home-container {
        height: 100vh;
    }

    .header {
        width: 90%;
    }

    .header-title {
        font-size: 70px;
        margin: 6rem 0;
        text-align: center;
        color: white;
    }

    .header-subtitle {
        color: #E8C547;
        font-size: 20px;
        font-weight: 300;
        width: 80%;
        text-align: center;
    }

    .search-holder {
        flex-direction: column;
    }

    .input-search {
        width: 100%;
        max-width: 300px;
    }

}

@media (max-width: 480px) {

    .home-container{
        width: 100%;
    }
    .header {
        width: 80%;
    }

    .header-title{
        font-size: 55px;
    }


    .header-subtitle {
        font-size: 20px;
        width: 90%;
        margin: 1rem 0;
    }

    .search-holder {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .input-search {
        width: 100%;
        max-width: 300px;
        margin: 1rem 0;
        height: 2rem;
        font-size: 14px;
    }

}

@media screen and (max-width: 400px) {

    .home-container {
        width: 100%;
    }

    .header-title{
        width: 100%;
        font-size: 12vw;
    }

    .header-subtitle {
        font-size: 18px;
        width: 90%;
        margin: 1rem 0;
    }

    .search-holder {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .input-search {
        width: 100%;
        max-width: 300px;
        margin: 1rem 0;
        height: 2rem;
    }

}