.App {
  text-align: center;
}

/* Target the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  /* Width of the scrollbar */
  height: 10px;
  /* Height of the scrollbar */
}

/* Track (background) of the scrollbar */
::-webkit-scrollbar-track {
  background: #4D5061;
  /* Color of the track */
}

/* Handle (thumb) of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #e8c547;
  /* Color of the handle */
  border-radius: 5px;
  /* Rounded corners of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the handle on hover */
}

