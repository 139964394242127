/* Global Box-Sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* About Container and Title Styling */
.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    min-height: 100vh;
    background-color: #000000;
    padding: 20px;
}

.title-about {
    font-size: 3.75rem;
    /* 60px */
    color: #E8C547;
    font-family: "Candlefish";
    width: 90%;
    text-align: center;
    margin-bottom: 2rem;
}

.accordion-container {
    width: 70%;
    max-width: 100%;
}

.accordion-item {
    background-color: #f8f9fa;
    border: none;
    color: #30323d;
    cursor: pointer;
    padding: 15px;
    width: 100%;
    border-radius: 5px;
    margin: 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.accordion-item:hover {
    background-color: #E8C547;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.accordion-item.active {
    background-color: #E8C547;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.473);
}

.accordion-title {
    font-weight: bold;
    margin: 0;
    font-size: 1.125rem;
    /* 18px */
}

.accordion-content {
    padding: 10px 0;
    font-size: 1.125rem;
    /* 18px */
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.6);
}

.partners-container {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 70%;
    max-width: 100%;
}

.partners-cards{
    display: flex;
    gap: 5%;
}

.partners-title {
    font-size: 2.8125rem;
    /* 45px */
    color: #E8C547;
    font-family: "Candlefish";
    text-align: center;
    margin-bottom: 2rem;
}

.partner {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 50%;
    padding: 2%;
    background-color: #f8f9fa;
    border-radius: 20px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.partner:hover {
    background-color: #30323d;
}

.partner-logo {
    width: 50px;
    /* Adjusted size */
    margin-right: 10px;
}

.partner-text {
    font-size: 1rem;
    /* 15px */
    width: 90%;
    text-align: justify;
}

.partner-link {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
}

.partner-link:hover {
    color: #E8C547;
}

/* Media Queries for Responsiveness */
@media (max-width: 950px) {
    .title-about {
        font-size: 2rem;
        /* 32px */
        width: 90%;
        margin: 15% 0;
    }

    .partners-title {
        font-size: 2rem;
        /* 32px */
    }

    .partners-cards{
        flex-direction: column;
    }

    .partner{
        width: 100%;
    }

    .accordion-container,
    .partners-container {
        width: 70%;
    }
}

@media (max-width: 600px) {
    .title-about {
        font-size: 1.75rem;
    }

    .partners-title {
        font-size: 1.75rem;
        /* 28px */
    }

        .accordion-container,
        .partners-container {
            width: 90%;
        }

    .partner {
        flex-direction: column;
        align-items: center;
    }

    .partner-logo {
        width: 40px;
        margin-bottom: 10px;
    }


    .partner-text {
        width: 70%;
        text-align: center;
    }
}