body {
  margin: 0;
  font-family: "Candlefish";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  background-color: #E8C547;
}


html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.whatsapp-widget-button {
  font-family: "Roboto", sans-serif;
  /* Exemplu de schimbare a fontului */
  background-color: #25d366;
  /* Exemplu de schimbare a culorii de fundal */
  color: #a01010bb;
  /* Exemplu de schimbare a culorii textului */
  border-radius: 38px;
  /* Exemplu de adăugare a unui border-radius */
  /* Alte stilizări CSS */
}

/* Exemplu de stilizare pentru butonul WhatsApp */
.whatsapp-widget-button {
  font-family: "Roboto", sans-serif;
  background-color: #25d366;
  color: #fff;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.whatsapp-widget-button:hover {
  background-color: #128c7e;
}