

.contact-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    padding: 1rem;
}

/* Top Text */
.top-text {
    font-size: 10rem;
    /* Adjusted for responsiveness */
    font-family: "Candlefish";
    color: rgb(0, 0, 0);
    text-align: center;
    margin: 0 1rem;
    /* Added margin for better spacing on small screens */
}

/* Lower Text */
.lower-text {
    text-decoration: none !important;
    font-size: 6rem;
    /* Adjusted for responsiveness */
    font-family: "Candlefish";
    color: #303030;
    display: flex;
    text-align: center;
    margin: 0 1rem;
    /* Added margin for better spacing on small screens */
}

/* Lower Text Hover */
.lower-text:hover {
    color: #ffffff;
}

@media (max-width: 950px) {

    .contact-container {
        width: 100%;
        margin: 0;
    }

    .top-text {
        font-size: 7rem;
        /* Increase font size for medium screens */
        width: 90vw;
        margin: 0;
    }

    .lower-text {
        font-size: 4rem;
        margin: 0;
    }
}

/* Media Queries */
@media (max-width: 768px) {

    .contact-container{
            width: 100%;
        margin: 0;
    }

    .top-text {
        font-size: 6rem;
        margin: 0;
    }

    .lower-text {
        font-size: 4rem;
        margin: 0;
    }
}

@media (max-width: 550px) {

    .contact-container {
        width: 100%;
        margin: 0;
    }

    .top-text {
        font-size: 4rem;
        /* Increase font size for medium screens */
        width: 90%;
        margin: 0;
    }

    .lower-text {
        width: 80%;
        font-size: 1.8rem;
        margin: 0;
    }
}