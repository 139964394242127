/* Global Box-Sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Existing CSS */
.footer {
    background-color: #E8C547;
    color: #000000;
    display: flex;
    min-height: 30dvh;
    flex-direction: column;
    justify-content: center;
    /* Ensure only one flex direction is applied */
    align-items: center;
    width: 100%;
    overflow-x: hidden;
}

.footer-top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
}

.logo-container {
    display: flex;
    align-items: center;
}

.footer-logo {
    width: 15rem;
    margin-right: 10px;
}

.footer-text {
    font-weight: bold;
}

.nav-links2-footer {
    display: flex;
    gap: 20px;
    cursor: pointer;
}

.footer-link {
    text-decoration: none;
    font-size: 1.5rem;
    transition: transform 0.3s ease-in-out;
}

.footer-link:hover {
    transform: scale(1.3);
    
}

.footer-bottom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 2%;
    width: 90%;
}

.footer-bottom-link {
    text-decoration: none;
    font-size: 2rem;
}

.footer-bottom-text {
    font-size: 2rem;
    text-align: center;
}

/* Media Queries */
@media (max-width: 950px) {

    .footer-top,
    .footer-bottom {
        width: 100%;
    }
}

@media (max-width: 600px) {

    .footer-top{
        flex-direction: column;
    }
    .nav-links2 {
        align-items: center;
        gap: 10px;
    }

    .footer-bottom {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}