/* privacy.css */

.privacy-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
}

.privacy-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
}

.privacy-content h1,
.privacy-content h2 {
    color: #333;
}

.privacy-content p {
    color: #666;
    line-height: 1.6;
}

.privacy-content h1 {
    margin-bottom: 20px;
}

.privacy-content h2 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.privacy-content p {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .privacy-content {
        padding: 20px;
    }

    .privacy-content h1 {
        font-size: 24px;
    }

    .privacy-content h2 {
        font-size: 18px;
    }

    .privacy-content p {
        font-size: 16px;
    }
}