/* Medic Card */
.medic-card {
    border: none;
    border-radius: 20px;
    padding: 3%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 700px;
    text-decoration: none;
    margin: 3rem auto;
    flex-grow: 1;
    /* Ensure all cards grow equally */
}

.medic-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}


.distance{
    color: black;
    width: 90%;
    border: 2px solid green;
}

.medic-image {
    width: 20rem;
    height: 25rem;
    border-radius: 20px;
    overflow: hidden;
    object-fit: contain;
    position: relative;
    top: -4rem;
    background-color: #ffffff;
    align-self: center;
}

.medic-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    flex-grow: 1;
    /* Ensure info section also grows */
}

.medic-name {
    font-size: 24px;
    font-weight: bold;
    margin: 8px 0;
    align-self: center;
}

.medic-specialization {
    font-size: 18px;
    color: #007bff;
    margin-bottom: 8px;
    align-self: center;
}

.medic-description {
    font-size: 16px;
    color: #666666;
    padding: 0 20px;
    text-align: center;
    margin-bottom: 20px;
}

.set-width1 {
    text-align: center;
    font-size: 1.5rem;
}

.set-width{
    margin: 2%;
    width: 100%;
}

.medic-info-item {
    font-size: 16px;
    color: #666666;
    padding: 0 20px;
    text-align: left;
    margin-bottom: 8px;
}

.medic-info-item b {
    font-weight: bold;
}

.social-icons {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    align-self: center;
}

.social-icons a {
    color: #666666;
    font-size: 20px;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #007bff;
}

.text-card {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.buttons-card{
    display: flex;
    height: fit-content;
    gap: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.holder-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.holder-icon:hover{
    transform: scale(1.2);
}

.icon-card-button{
    width: 70%;
}

.descriere{
    height: 30%;
    text-align: justify;
    margin: 7% 0;
}

.reviews{
    margin: 5% auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.appointment-button {
    position: relative;
    display: inline-block;
    background: linear-gradient(to right, #d4b43b, #FF7730);
    background-size: 200% auto;
    border: none;
    max-width: 80%;
    font-weight: 700;
    border-radius: 25px;
    color: rgb(0, 0, 0);
    font-size: 1rem;
    text-transform: uppercase;
    box-shadow: 0 0 0 0 rgba(17, 8, 8, 0.7);
    animation: glowing-pulse 2s infinite;
    transition: color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    align-self: center;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.full-star {
    color: gold;
    /* Culoarea pentru stelele pline */
}

.half-star {
    color: gold;
    /* Culoarea pentru steaua pe jumătate */
}

.empty-star {
    color: #e0e0e0;
    /* Culoarea pentru stelele goale */
}

.appointment-button::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 30px;
    box-shadow: 0 0 20px #d4b43b;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

.appointment-button:hover {
    color: #ffffff;
    text-shadow: 2px 2px 2px black;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.05);
    background-position: right center;
}


.reviews {
    position: relative;
    cursor: pointer;
}

/* .medic-card:hover {
    box-shadow: inset 0 0 10px 5px #d4b43b;
} */

.popup {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 1000;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.reviews:hover .popup {
    visibility: visible;
    opacity: 1;
}

@keyframes glowing-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(66, 66, 66, 0.279);
    }

    50% {
        box-shadow: 0 0 0 20px rgba(77, 77, 77, 0.2);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@media screen and (max-width: 768px) {
    .medic-card {
        max-width: 90%;
        padding: 12px;
        height: 700px;
        /* Fixed height for smaller screens */
    }


        .medic-name {
            font-size: 18px;
        }
    
        .medic-image {
            height: 20rem;
            width: 20rem;
            margin-top: -2rem;
        }
        
    .medic-name {
        font-size: 22px;
    }

    .medic-specialization,
    .medic-description {
        font-size: 14px;
    }

    .appointment-button {
        font-size: 14px;
    }
}

@media screen and (max-width: 500px) {
    .medic-card {
        max-width: 70%;
        padding: 10px;
        height: 700px;
        /* Fixed height for smaller screens */
    }

         .medic-image {
             height: 15rem;
             width: 15rem;
             margin-top: -2rem;
         }

    .medic-specialization,
    .medic-description {
        font-size: 11px;
    }

    .appointment-button {
        font-size: 11px;
    }

        .reviews {
            margin-top: 10%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
}